import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

export function DonutChart({values}) {
    const data = {
        labels: ['Importada', 'Autoconsumo'],
        datasets: [
            {
                label: '% de Energía',
                data: values,
                backgroundColor: [
                'rgba(207, 4, 82, 0.5)',
                'rgb(112,148,228)',
                ],
                borderColor: [
                'rgba(240, 0, 120, 0.8)',
                'rgb(112,148,228)',
                ],
                borderWidth: 1,
            },
        ],
    }
  return <Doughnut data={data} />;
}