import { Flex, Text } from "@chakra-ui/react"

export default function StatItem({device, text, stat, icon}){
    var stat = stat.replace('.',',');
    
    if (device === 'd'){
    return(
        <Flex bg='linear-gradient(90deg,#A2DF76 0%,#224A05 100%)'
        rounded='lg' justify='space-between' gap='1em' p='1.2em'  align='center'>
            {icon}  
            <Flex direction='column' align='end' >
                <Text color='white'> {text} </Text>
                <Text color='white' fontWeight='bold'> {stat } </Text>
            </Flex>      
        </Flex>
    )
    }
    return(
        <Flex bg='linear-gradient(90deg,#A2DF76 0%,#224A05 100%)' direction='column'
        rounded='lg' justify='space-between' gap='0.5em' py='0.5em' px='0.25em' align='center'>
            {icon}  
            <Text color='white' px='0.25em' textAlign='center' fontSize='14px'> {text} </Text>
            <Text color='white' fontSize='16px' > {stat } </Text>
        </Flex>
    )
}