import {Chart as ChartJS,CategoryScale,LinearScale,PointElement,LineElement,Title,Tooltip,Filler,Legend,} from 'chart.js';
import { Line } from 'react-chartjs-2';
import moment from 'moment/moment';

export default function AreaChart({timeStamp,imported, solar, total}) {
    ChartJS.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        Title,
        Tooltip,
        Filler,
        Legend
    );
    const options = {
        responsive: true,
        
        plugins: {
          legend: {
            position: 'top',
        },
        }
    }
    
    const labels = timeStamp?.map(date => moment(date).format('DD/MM hh:mm'))
    const data = {
    	labels,
        datasets: [
			{
				fill: true,
				label: 'Autoconsumo',
				data: solar,
				borderColor: 'rgb(112,148,228)',
				backgroundColor: 'rgba(112,148,228,0.5)',
			},
			{
				fill: true,
				label: 'Consumo',
				data: total,
				borderColor: 'rgba(240, 0, 120, 0.8)',
				backgroundColor: 'rgba(207, 4, 82, 0.5)',
			},
        ],
    }
    return (
        <Line options={options} data={data}/>
    )
}
