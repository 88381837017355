import {Grid } from '@chakra-ui/react'
//comps
import StatItem from './StatItem'
//icons
import {MdOutlineSavings} from 'react-icons/md'
import {FaSolarPanel} from 'react-icons/fa'
import {AiOutlineLineChart, AiOutlineDownload} from 'react-icons/ai'


export default function StatGrid({device, data}){

    return(
        <Grid templateColumns= {device==='d'?'repeat(3, 1fr)':'repeat(2, 1fr)'}  gap={6} px={device==='d'?'5%':'0.5em'}  mb='8vh'>
            <StatItem text={'Ahorro hoy'} stat={ parseFloat(data?.saving.saving).toFixed(2)+' €'} 
                icon={<MdOutlineSavings color='white' size='30px' />} />
            <StatItem text={'Energía Producida Hoy'} 
                stat={data?.energyOverview.lastDayData/1000 +' kWh'} 
                icon={<FaSolarPanel color='white' size='25px' />} />
            <StatItem text={'Energía Producida Total'} 
                stat={parseFloat(data.energyOverview.lifeTimeData/1000000).toFixed(2)+' MWh'} 
                icon={<FaSolarPanel color='white' size='25px' />}/>
            <StatItem text={'Consumo ahora'} 
                stat={data?.siteCurrentPowerFlow?.LOAD.currentPower !== undefined? 
                    data?.siteCurrentPowerFlow?.LOAD.currentPower+' kW' : 'Sin datos'} 
                icon={<AiOutlineLineChart color='white' size='30px'/>} />
            <StatItem text={'Importada ahora'} 
                stat={data?.siteCurrentPowerFlow?.GRID.currentPower !== undefined? 
                    data?.siteCurrentPowerFlow?.GRID.currentPower+' kW' : 'Sin datos'}  
                icon={<AiOutlineDownload color='white' size='30px' />} />
            <StatItem text={'Autoconsumo ahora'} 
                stat={data?.siteCurrentPowerFlow?.PV.currentPower !== undefined? 
                    data?.siteCurrentPowerFlow?.PV.currentPower+' kW' : 'Sin datos'}  
                icon={<FaSolarPanel color='white' size='25px' />} />
        </Grid>
    )
}