import {Flex, Input, Text} from '@chakra-ui/react'
import { useState, useEffect } from 'react'
import moment from 'moment'
//charts
import AreaChart from './Charts/AreaChart'
import { DonutChart } from './Charts/DonutChart'
//api
import { getGraphs } from '../api/statsAPI'
import { useMutation } from '@tanstack/react-query'

export default function Graphs({station, device}){

    //format required for those inputs
    const [from, setFrom] = useState(moment().subtract(1,'days').format('YYYY-MM-DD'))
    const [to, setTo] = useState(moment().format('YYYY-MM-DD'))

    const {mutate, isLoading, data , error}= useMutation(
        getGraphs,
        {
            onError: ()=>{
                console.log(error)
            }
        }
    )

    //dependency with the station and date ranges
    useEffect(() => {
        mutate({
            id:station, 
            dateRange:{
                startTime:from,
                endTime:to
            }
        })
    }, [from, to, station])

    return(
        device === 'd' ? 
        <Flex direction='column' py='10vh' bg='white' px='5%'>
            {/* date input  */}
            <Flex justify='space-around' align='center' mb='5vh'>
                <Text fontWeight='bold' >Seleccione un rango de fechas</Text>
                <Flex gap='2em' align='center'>
                    <Text>Desde</Text>
                    <Input type='date' value={from} onChange={(e)=>setFrom(e.target.value) } />
                    <Text>Hasta</Text>
                    <Input type='date' value={to} onChange={(e)=>setTo(e.target.value) } />
                </Flex>
            </Flex>
            
            <Flex w='100%' maxH='600px'  mb='5vh'  >
                <Flex w='60%' justify='center' align='center' p='1em'>
                    <AreaChart timeStamp={data?.timeStamp} imported={data?.importedPower}  solar={data?.solarPower}  total={data?.totalPower} />
                </Flex>
                <Flex  w='40%' justify='center' align='center' p='6em' >
                    <DonutChart values={[data?.importedRelative, data?.solarRelative]} />
                </Flex>
            </Flex>
        </Flex>
        : //mobile version
        <>
        <Flex direction='column' py='5vh' px='2em' w='100%' mb='2em' bg='white'>
            <Text fontWeight='bold' >Seleccione un rango de fechas</Text>
            <Flex direction='column' py='2em'  gap='1em'>
                <Flex gap='0.5em'  direction='column'> 
                    <Text>Desde</Text>
                    <Input type='date' maxW='60%'  value={from} onChange={(e)=>setFrom(e.target.value) } />
                </Flex>
                <Flex gap='0.5em'   direction='column' >
                    <Text>Hasta</Text>
                    <Input type='date' maxW='60%' value={to} onChange={(e)=>setTo(e.target.value) } />
                </Flex>
            </Flex>         
        </Flex>
        <Flex mb='2em' w='100%' p='0' justify='center' align='center'  >
            <AreaChart  timeStamp={data?.timeStamp} imported={data?.importedPower}  solar={data?.solarPower}  total={data?.totalPower} />
        </Flex>
        <Flex >
            <DonutChart values={[data?.importedRelative, data?.solarRelative]} /> 
        </Flex>
         </>
       
    )
}