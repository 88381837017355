import {Flex, Image, Text } from '@chakra-ui/react'
import { useState } from 'react'

//comps
import TopNav from '../components/TopNav'
import StatGrid from '../components/StatGrid'
import Graphs from '../components/Graphs'
//image
import bg from '../img/paneles.png'
import fabrica from '../img/icons/fabrica.png'
import solarPanel from '../img/icons/panel-solar.png'
import red from '../img/icons/red.png'
//api
import { getResume } from '../api/statsAPI'
import { useQuery } from '@tanstack/react-query'

export default function Home ({device}) {

    const [station, setStation] = useState()
    const selectStation = (site) => {
        setStation(site)
        //mutate(site.id)
    }
    const {data, isLoading} = useQuery({
        queryKey: ['resume', station?.id],
        queryFn: () => getResume(station?.id),
        cacheTime: 30000,
        enabled: !!station,
    })

    const replaceDot = (input) => {
        return String(input).replace('.',',')
    }

    return(
        <Flex direction='column' w='100%'  align='center'  >
            
            <TopNav device={device} station={station} setStation= {setStation} selectStation={selectStation} />
            
            { isLoading && 
                <Flex  minH='90vh' justify='center' align='center' >
                    <Text color='green.400' as='h2' px='5%' mb='5vh' >
                        Cargando...
                    </Text>
                </Flex>
            }
            {JSON.stringify(data)!=='{}' && data && !isLoading &&
            //banner for alcala station d version
            <>
            {device==='d'?
            <Flex direction='column' bgImage={bg}  align='center'
            bgPosition="center" bgSize='100%' bgRepeat="no-repeat" w='100%'>
                <Flex   p='2em'  bg='whiteAlpha.600' rounded='lg' 
                justify='center' gap='4em' align='center'  my='10vh'>
                    <Flex direction='column' align='center' >
                        <Image src={solarPanel}  />
                        <Text color='green' as='h3' >{replaceDot(data.siteCurrentPowerFlow?.PV?.currentPower)} kW</Text>
                    </Flex>
                    <Flex direction='column' align='center'>
                        <Image src={fabrica} maxW='400px' />
                        <Text color='green' as='h3'>{replaceDot(data.siteCurrentPowerFlow?.LOAD?.currentPower) } kW </Text>
                    </Flex>
                    <Flex direction='column' align='center'>
                        <Image src={red} maxW='400px' />
                        <Text color='green' as='h3' >{replaceDot(data.siteCurrentPowerFlow?.GRID?.currentPower)} kW </Text>
                    </Flex> 
                </Flex>
                {/* First banner grid */}
                <StatGrid device={device} data={data} />
            </Flex>
            : //mobile version
            <Flex direction='column' bg='gray.200'  w='100%' align='center'  >
                {/*  */}
                <Flex   p='1em' justify='center' gap='4em' align='center' mb='2em'>
                    <Flex direction='column' align='center' >
                        <Image src={solarPanel} />
                        <Flex align='center' gap='0.2em'>
                            <Text color='#6bcbe9' fontSize='16px' >{replaceDot(data.siteCurrentPowerFlow?.PV?.currentPower)}</Text>
                            <Text  color='#6bcbe9' fontSize='12px' >kW</Text>
                        </Flex>
                    </Flex>
                    <Flex direction='column' align='center'>
                        <Image src={fabrica}    />
                        <Flex align='center' gap='0.2em'>
                            <Text color='#4764678f'  fontSize='16px' >{replaceDot(data.siteCurrentPowerFlow?.LOAD?.currentPower)}</Text>
                            <Text  color='#4764678f' fontSize='12px' >kW</Text>
                        </Flex>
                    </Flex>
                    <Flex direction='column' align='center'>
                        <Image src={red}   />
                        <Flex align='center' gap='0.2em'>
                            <Text color='#e16868'  fontSize='16px' >{replaceDot(data.siteCurrentPowerFlow?.GRID?.currentPower)} </Text>
                            <Text  color='#e16868' fontSize='12px' >kW</Text>
                        </Flex>
                    </Flex>
                </Flex>
                
                {/* First banner grid*/} 
                <StatGrid device={device} data={data} /> 
                
            </Flex>    
            }
        

            {/* Gradient banner */}
            {device === 'd' ? 
            <Flex w='100%' justify='center' align='center' gap='15%' py='5vh'
            bg='linear-gradient(0deg,#A2DF76 0%,#224A05 100%)'
            >
                <Flex direction='column' gap='1em' align='center' p='4em'>
                    <Text as='h1' color='white' > {Math.round(data.envBenefits.treesPlanted)} </Text>
                    <Text as='h4' color='white'>Árboles Equivalentes Plantados</Text>
                </Flex>
                <Flex direction='column' gap='1em' align='center' p='4em'>
                    <Text as='h1' color='white' >{replaceDot(parseFloat(data.envBenefits.co2).toFixed(2))} Kg</Text>
                    <Text as='h4' color='white'>Emisiones de CO2 Ahorradas</Text>
                </Flex>
            </Flex>
            ://mobile version 
            
            <Flex w='100%' gap='2em' align='center' py='4em'   direction='column'
            bg='linear-gradient(310deg,#A2DF76 0%,#224A05 100%)'
            >   
                <Flex direction='column' gap='0.5em' align='center' >
                    <Text as='h3' color='white'> {Math.round(data.envBenefits.treesPlanted)} </Text>
                    <Text color='white' >Árboles Equivalentes Plantados</Text>
                </Flex>
                <Flex direction='column' gap='0.5em' align='center' >
                    <Text as='h3' color='white' > {replaceDot(parseFloat(data.envBenefits.co2).toFixed(2))} kg</Text>               
                    <Text fontSize='16px' color='white'>Emisiones de CO2 Ahorradas</Text>
                </Flex>
            </Flex>

            }


            {/* Datetime graphs */}             
            <Graphs station={station?.id} device={device} /> 
            

            </>
            }
            { !data && !isLoading &&
            <Flex  minH='90vh'  justify='center' align='center' >
                <Text as='h2' color='green.400' px='5%' mb='5vh' >
                    Selecciona un emplazamiento
                </Text>
            </Flex>
            }
        </Flex>
    )
}