import {Flex, Image, Text, Button,Menu,MenuButton,MenuList,MenuItem} from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
//image
//import logo from '../img/logo.png'
import logo from '../img/logos/newlogo.png'
//icons
import { FaChevronDown } from 'react-icons/fa'
import {FiLogOut} from 'react-icons/fi'
//auth
import { useSignOut } from 'react-auth-kit'
//api
import { getSites } from '../api/statsAPI'
import { useQuery} from '@tanstack/react-query'

export default function TopNav({station, selectStation, device}){

    const signOut = useSignOut()
    const navigate= useNavigate()

    const {isSuccess, data} = useQuery(
        ["sites"],
        ()=>getSites(),
        { staleTime: 60000 }
    );
    if(isSuccess && station===undefined ){
        selectStation(data.sites.site[5])
    }
    if(device === 'd'){
    return(
        <Flex w='100%' bg='white' justify='space-between' px={device==='d'?'8%': '0.5em'}  py={device==='d'?'2em': '0.5em'} align='center'  >
            <Image src={logo} h={device==='d'?'60px': '30px'}/>
            <Flex align ='center'  gap='2em' >
                {device === 'd' &&
                <Text fontWeight='bold'  color='green' >Selección de planta</Text>
                }
                <Menu >
                    <MenuButton bg='linear-gradient(0deg,#A2DF76 0%,#224A05 100%)' size={device==='d'?'md': 'xs'}  color='white'
                    as={Button} rightIcon={<FaChevronDown />}>
                        {station?.name}
                    </MenuButton>
                    <MenuList>
                        {
                            data?.sites.site
                                .filter(site=> site.id===2050705 || site.id===2050894 || site.id === 1135635 )
                                .map((site)=>
                                <MenuItem key={site.id} onClick={()=> selectStation(site)}  >{site.name} </MenuItem>
                            )
                        }
                    </MenuList>
                </Menu>
                <FiLogOut color='green'  size='25px'
                onClick={()=>{signOut();navigate('/login')}}
                cursor = {'pointer'}
                />
            </Flex>
        </Flex>
    )
    }
    return(
        <Flex w='100%' bg='white'py= '1em' justify='space-between' px='1em' align='center'  >
            <Image  src={logo} h={'40px'}/>
            <Flex align ='center'  gap='2em' >
                {device === 'd' &&
                <Text fontWeight='bold'  color='green' >Selección de planta</Text>
                }
                <Menu >
                    <MenuButton bg='linear-gradient(0deg,#A2DF76 0%,#224A05 100%)' fontWeight='400' size='xs'  color='white'
                    as={Button} rightIcon={<FaChevronDown />}>
                        {station?.name}
                    </MenuButton>
                    <MenuList>
                        {
                            data?.sites.site
                                .filter(site=> site.id===2050705 || site.id===2050894 || site.id === 1135635 )
                                .map((site)=>
                                <MenuItem key={site.id} onClick={()=> selectStation(site)}  >{site.name} </MenuItem>
                            )
                        }
                    </MenuList>
                </Menu>
                <FiLogOut color='green'  size='20px'
                onClick={()=>{signOut();navigate('/login')}}
                cursor = {'pointer'}
                />
            </Flex>
        </Flex>
    )
    
}