import axios from "axios";

const statsApi = axios.create({
    baseURL: process.env.REACT_APP_API_URL+'/stats'
})

//get all sites and general info from eachone
export const getSites = async() => {
    const response =  await statsApi.get('/list/stats');
    return response.data
}

//get array data for charts POST
export const getGraphs = async(payload) => {
    const response =  await statsApi.post('/graphs/'+payload.id,
    payload.dateRange
    );
    return response.data
}

//get necessary stats for one site
export const getResume = async(id) => {
    //console.log('fetching resume')
    const response =  await statsApi.get('/resume/' + id );
    return response.data
}

//get general stats from one site
export const getOverview = async(id) => {
    const response =  await statsApi.get('/overview/' + id );
    return response.data
}

//get the power flow meaning from PV to grid, etc...
export const getPowerFlow = async(id) => {
    const response =  await statsApi.get('/powerFlow/' + id );
    return response.data
}
