import { Flex, Button, Checkbox, Image, useToast } from "@chakra-ui/react"
import { useNavigate } from "react-router-dom"
//icons
import {FaUserAlt} from 'react-icons/fa'
import {RiLockPasswordFill} from 'react-icons/ri'
//api
import {login} from '../api/authAPI'
import { useMutation } from "@tanstack/react-query"
//auth
import { useSignIn } from "react-auth-kit"
//validation
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import InputField from '../components/Validation/InputField'
//img
import logo from '../img/logos/newlogo.png'

export default function Login(){

    const navigate = useNavigate()
    const toast = useToast()
    const signIn = useSignIn()

    const {isLoading, mutate} = useMutation(
        ["login"],
        login,
        {
        onSuccess: (data) => {
            toast({title: 'Login exitoso!',status:"success"})
            signIn({
                token: data.access_token,
                expiresIn: 3600,
                tokenType: "Bearer",
                authState: {username: data.user.nameSocial, uuid:data.user.uuid },           
            })
            navigate('/')
        },
        onError : (error)=>{
            toast({title: error.message, description: error.response.data?.message ,status:"error"})
        }
        }
    );
    //vaaalidation for login
    const LoginSchema = Yup.object({
        password: Yup.string()
            .min(6, 'Demasiado corta')
            .required('Es obligatorio'),
        email: Yup.string().email('Formato de email inválido').required('Es obligatorio'),
        });   

    return(
    <Flex w='100%' minH='100vh' bg='gray.200' justify='center' align='center'>
            <Formik
                initialValues={{
                    password: '',
                    email: '',
                }}
                validationSchema={LoginSchema}
                onSubmit={values => {
                    mutate(values);
                    }}
            >
                {(formik ) => (
                <Flex bg='white' rounded='lg' direction='column' gap='1em' p='2em' align='center' boxShadow='lg' >
                    <Image src={logo} w='200px' mt='-80px' p='0.5em' />  
                    <Flex align='center' gap='1em' mt='2em' >
                        <Flex bg='green' p='0.5em ' rounded='md'>
                            <FaUserAlt color='white' />
                        </Flex>
                        <InputField name='email' bg='grey'  />
                    </Flex>
                    <Flex align='center' gap='1em' >
                        <Flex bg='green'  rounded='md' p='0.5em'>
                            <RiLockPasswordFill color='white' />
                        </Flex>
                        <InputField  name='password' type='password' bg='grey' />
                    </Flex>         
                    <Button alignSelf='center' w='150px' variant='green'  mt='2em'
                    isDisabled={JSON.stringify(formik.errors) !== '{}' } isLoading={isLoading} onClick={formik.handleSubmit}
                    >Login</Button>
                </Flex>
                )}
            </Formik>
    </Flex>
    )
}